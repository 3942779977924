const COLUMNS_LAYOUT_DIVIDED_LEFT_SIDEBAR = 'divided-left-sidebar';
const COLUMNS_LAYOUT_DIVIDED_LEFT_SIDEBAR_FULL = 'divided-left-sidebar-full';
const COLUMNS_LAYOUT_SIDEBAR = 'sidebar';
const COLUMNS_LAYOUT_SIDEBAR_FULL = 'sidebar-full';
const COLUMNS_LAYOUT_FULL_WIDTH = 'full-width';
const COLUMNS_LAYOUT_SINGLE = 'single';
const COLUMNS_LAYOUT_WIDE = 'wide';
const COLUMNS_LAYOUT_WIDE_SIDEBAR = 'wide-sidebar';

export { COLUMNS_LAYOUT_DIVIDED_LEFT_SIDEBAR, COLUMNS_LAYOUT_DIVIDED_LEFT_SIDEBAR_FULL, COLUMNS_LAYOUT_FULL_WIDTH, COLUMNS_LAYOUT_SIDEBAR, COLUMNS_LAYOUT_SIDEBAR_FULL, COLUMNS_LAYOUT_SINGLE, COLUMNS_LAYOUT_WIDE, COLUMNS_LAYOUT_WIDE_SIDEBAR };
