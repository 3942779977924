import React from 'react'

import {
  Button,
  Buttons,
  Columns,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  List,
  ListItem,
  Main,
  ModalBody,
  ModalContent,
  Paragraph,
  PostponeIcon,
  Spacer,
  Stack,
  Strong,
  Textarea
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'
import { Link } from '../../components/Link'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Jatka myöhemmin -toiminto">
    <Section title="Periaatteet">
      <List variant="unordered">
        <ListItem>
          <Strong>Jatka myöhemmin</Strong> -toimintoa käytetään lomakkeilla,
          joissa on automaattitallennus. Toiminnon avulla poistutaan lomakkeelta
          niin, että käyttäjä voi jatkaa sen täyttämistä myöhemmin. Palattaessa
          monisivuinen lomake avautuu ensimmäiselle sivulle.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <Strong>Jatka myöhemmin</Strong> -toiminto on hyvä löytyä kaikilta
              lomakkeilta, joiden täyttäminen voidaan tehdä osissa.
            </ListItem>
            <ListItem>
              Hyvin lyhyillä ja suoraviivaisilla lomakkeilla ei ole tarvetta{' '}
              <Strong>Jatka myöhemmin</Strong> -toiminnolle.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>Jatka myöhemmin</Strong> -toiminto on painike lomakkeen
          alaosassa. Painikkeen painamisen jälkeen esitetään varmistusdialogi (
          <Code>
            <Link page="Modal" />
          </Code>
          ), jolla hyväksytään myöhemmin jatkaminen tai peruutetaan takaisin
          lomakkeelle.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <NoteItem
        bullet="1"
        title="Jatka myöhemmin -painike lomakkeella"
      />
      <NoteItem
        bullet="2"
        title="Varmistusdialogin otsikko"
      />
      <NoteItem
        bullet="3"
        title="Varmistusdialogin sisältö"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Tässä kerrotaan, että lomakkeen tiedot on tallennettu. Lisäksi
            voidaan mainita mm. lomakkeen tilaan liittyviä asioita, lomakkeen
            lähettämisen määräpäivä tai mitä tapahtuu, jos lomaketta ei
            lähetetä. Katso esimerkit alta.
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="4"
        title="Varmistusdialogin painikkeet"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <Strong>Peruuta</Strong>-toiminto palaa takaisin lomakkeelle.{' '}
            <Strong>Jatka myöhemmin</Strong> -toiminto jättää lomakkeen
            keskeneräiseksi ja sulkee lomakkeen.
          </ListItem>
        </List>
      </NoteItem>
      <ZoomedExample
        notes={[{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }]}
      >
        <Columns layout="single">
          <Main>
            <FormLayout>
              <FormLayoutItem>
                <FormGroup noMargin>
                  <Textarea label="Lorem ipsum dolor sit amet" />
                </FormGroup>
              </FormLayoutItem>
            </FormLayout>
            <Spacer
              marginTop="xl"
              marginBottom="layout-xl"
            >
              <Buttons align="center">
                <div data-note="1">
                  <Button
                    iconLeft={<PostponeIcon />}
                    variant="plain"
                  >
                    Jatka myöhemmin
                  </Button>
                </div>
              </Buttons>
            </Spacer>
            <ModalContent size="lg">
              <ModalBody>
                <div data-note="2">
                  <Heading
                    id="modal-heading"
                    size={4}
                  >
                    Haluatko jatkaa myöhemmin?
                  </Heading>
                </div>
                <div data-note="3">
                  <Paragraph>
                    Ilmoituksen tiedot on tallennettu, mutta et ole vielä
                    ilmoittautunut työnhakijaksi. Työnhakusi ei ole voimassa.
                  </Paragraph>
                </div>
                <div data-note="4">
                  <Buttons
                    align="right"
                    noMargin
                  >
                    <Button variant="outline">Peruuta</Button>
                    <Button>Jatka myöhemmin</Button>
                  </Buttons>
                </div>
              </ModalBody>
            </ModalContent>
          </Main>
        </Columns>
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <Stack gap="xl">
        <>
          <SectionHeading>Selvityspyyntö-lomakkeella</SectionHeading>
          <ModalContent size="lg">
            <ModalBody>
              <Heading
                id="modal-heading"
                size={4}
              >
                Haluatko jatkaa myöhemmin?
              </Heading>
              <Paragraph>
                Selvityksen tiedot on tallennettu, mutta et ole vielä lähettänyt
                sitä. Lähetä selvitys viimeistään 15.6.2021.
              </Paragraph>
              <Buttons
                align="right"
                noMargin
              >
                <Button variant="outline">Peruuta</Button>
                <Button>Jatka myöhemmin</Button>
              </Buttons>
            </ModalBody>
          </ModalContent>
        </>
        <>
          <SectionHeading>Työllistymissuunnitelma-lomakkeella</SectionHeading>
          <ModalContent size="lg">
            <ModalBody>
              <Heading
                id="modal-heading"
                size={4}
              >
                Haluatko jatkaa myöhemmin?
              </Heading>
              <Paragraph>
                Työllistymisuunnitelman tiedot on tallennettu, mutta et ole
                vielä lähettänyt sitä hyväksyttäväksi. Jos et tee
                työllistymissuunnitelmaa 15.6.2021 mennessä, työnhakusi päättyy.
              </Paragraph>
              <Buttons
                align="right"
                noMargin
              >
                <Button variant="outline">Peruuta</Button>
                <Button>Jatka myöhemmin</Button>
              </Buttons>
            </ModalBody>
          </ModalContent>
        </>
      </Stack>
    </Section>
  </Content>
)

export default Page
